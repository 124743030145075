const postData = async (data, timeout = 10000) => {
    // Timeout is set to 10 seconds by default
    const URL = process.env.REACT_APP_CORNELL_API_URL || 'http://35.153.52.66/shap'
    console.log("============ postData URL start ============");
    console.log(URL);
    console.log("============ postData URL end ============");

    // create an AbortController instance
    const ac = new AbortController();
    const signal = ac.signal;

    // set a timeout for the fetch call
    const timeoutId = setTimeout(() => {
        ac.abort();
    }, timeout);

    try {
        const res = await fetch(URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
            signal,
        });

        // clear the timeout
        clearTimeout(timeoutId);
        
        const result = await res.text();
        console.log("============ postData result start ============");
        console.log(result);
        console.log("============ postData result end ============");
        return result;
    } catch (error) {
        // handle the error
        clearTimeout(timeoutId); 
        if (error.name === 'AbortError') {
            console.error('Shap Fetch aborted due to timeout.');
        } else {
            console.error('Shap Fetch error:', error.message);
        }
    }

    
};

export default postData;